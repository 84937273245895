import * as React from 'react'

import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'

const PreviewPage = () => (
  <>
    <h1>Loading preview...</h1>
  </>
)

export default withPrismicPreviewResolver(PreviewPage)
